@import "../../template/variables";
@import "../../template/mixins";

.card {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    margin: 20px auto;

    @include mediaQuery(sm) {
        justify-content: center;
        align-items: center;
    }
}

.container {
    width: 100% !important;

    padding: 1rem;
    border-radius: 1rem;
    border: 2px solid rgba($primary, 0.2);
    box-shadow: 0 0.5rem 1rem rgba($primary, 0.175);

    @include mediaQuery(sm) {
        width: 450px !important;
        padding: 2rem;
    }

    @include mediaQuery(lg) {
        width: 450px !important;
        padding: 2rem;
    }
}

.input {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    margin-bottom: 20px;
}

.logo {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;

    @include mediaQuery(sm) {
        width: 175px;
    }
}

.button {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.75rem;
}
