@import "../../template/variables";

.header {
    height: $header-height;
}

.content {
    height: calc(100vh - $header-height);
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: 0;
}
