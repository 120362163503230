@import "./variables";

* {
    font-family: "Montserrat";
    letter-spacing: 0.0168em !important;
}

a {
    text-decoration: none !important;
    color: unset !important;
    &:hover {
        color: unset !important;
    }
}

body {
    margin: 0px;
    padding: 0px;
    overflow-y: hidden !important;
}

.btn {
    &:hover {
        border-color: transparent !important;
    }
}

.btn {
    font-weight: 500 !important;
    border-radius: 100px !important;
}

.btn-outline-primary {
    &:hover {
        color: white !important;
    }
}

.btn-primary {
    color: white !important;
    &:hover,
    &:active,
    &:focus {
        color: white !important;
    }
}

.rc-pagination-next,
.rc-pagination-prev,
.rc-pagination-options {
    display: none !important;
}

.rc-pagination-item {
    border-radius: 7px !important;
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    height: auto !important;
}

.rc-pagination-item-active {
    border-color: $primary !important;
}

.custom-checkbox .form-check-input {
    width: 1.5em !important;
    height: 1.5em !important;
    margin-top: 0 !important;
}

.custom-switch .form-switch {
    padding-left: 2.4em !important;
}

.custom-switch {
    width: 46px !important;
}

.custom-modal-footer {
    justify-content: start !important;
}

.password-container.form-control:disabled {
    background-color: inherit !important;
}

.table-tabs .nav-link.active {
    color: $primary !important;
    font-weight: bold !important;
}

.table-tabs .nav-link {
    color: gray !important;
    font-weight: normal !important;
}

.table-field.form-control:disabled {
    background-color: transparent !important;
}

.react-datepicker {
    border-radius: 0 !important;
}

.react-datepicker__day-name {
    color: white !important;
}

.react-datepicker__triangle,
.react-datepicker__navigation,
.react-datepicker__current-month {
    display: none !important;
}

.react-datepicker__year-select,
.react-datepicker__month-select,
.react-datepicker__header,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    border: 0 !important;
    border-radius: 0 !important;
    color: white !important;
    background-color: $primary !important;
}

.react-datepicker__close-icon::after {
    background-color: $primary !important;
    color: white !important;
    padding: 0 !important;
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
}

.circle-picker > span > div {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}

.circle-picker {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    gap: 0.5rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.basic-multi-select {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.user-segments-select__control,
.user-segments-select__menu {
    border-radius: 0 !important;
}

.user-segments-select__option {
    margin-bottom: 4px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.user-segments-select__menu-list {
    display: flex !important;
    gap: 4px !important;
    flex-wrap: wrap !important;
    padding: 6px 10px !important;
}

.user-segments-select__option {
    width: fit-content !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    padding: 3px 6px !important;
}

.campaign-segments-select__control,
.campaign-segments-select__menu {
    border-radius: 0 !important;
}

.campaign-segments-select__option {
    margin-bottom: 4px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.campaign-segments-select__menu-list {
    display: flex !important;
    gap: 4px !important;
    flex-wrap: wrap !important;
    padding: 6px 10px !important;
}

.campaign-segments-select__option {
    width: fit-content !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    padding: 3px 6px !important;
}

.campaign-filters-select__control,
.campaign-filters-select__menu {
    border-radius: 0 !important;
}

.campaign-filters-select__option {
    margin-bottom: 4px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.campaign-filters-select__menu-list {
    display: flex !important;
    gap: 4px !important;
    flex-wrap: wrap !important;
    padding: 6px 10px !important;
}

.campaign-filters-select__option {
    width: fit-content !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    padding: 3px 6px !important;
}

.react-datepicker-time__header {
    background-color: $primary !important;
    color: white !important;
    padding: 0 !important;
    font-weight: normal !important;
}

.react-datepicker__header--time {
    padding-top: 19px !important;
    padding-bottom: 18px !important;
}

.actions-dropdown .dropdown-toggle::after {
    display: none !important;
}

.description-editor .ql-editor {
    height: 200px !important;
}

.custom-map-marker {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background-color: $dark-gray !important;
    border: 1px solid black;
    border-radius: 1rem !important;
    margin-bottom: 4rem !important;
    color: white !important;
    font-weight: 900 !important;
}
