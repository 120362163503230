.button {
    position: absolute;
    right: 0.75rem;
    top: 25%;
    padding: 0;

    border: none !important;
    &:focus {
        border: none !important;
    }
}

.icon {
    width: 20px;
    height: 20px;

    color: #747c84 !important;
    z-index: 999;

    & > svg {
        display: flex;
    }
}
