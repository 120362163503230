@import "../../template/variables";
@import "../../template/mixins";

.container {
    height: 100%;
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 30px;
    padding-bottom: 60px;
}

.logo {
    width: 175px;
    margin-left: auto;
    margin-right: auto;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    :last-child {
        margin-top: auto;
    }
    :only-child {
        margin-top: 0;
    }
}

.link {
    display: flex;
    align-items: center;
    gap: 15px;
}

.icon-container {
    width: 25%;
    text-align: end;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;
}

.icon {
    width: 24px;
    height: 24px;
}

.label {
    margin: 0 !important;
    width: 75%;
    font-weight: 500;
}

.active-label {
    color: $primary;
}

.active-icon {
    background-color: $primary;
    color: white;
}
