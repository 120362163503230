$primary: #276eb6;
$dark: #101216;
$dark-gray: #333333;
$secondary: #f8f8f8;
$table-bg-scale: 0%;

$mediaQueryBreakPoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$header-height: 70px;
