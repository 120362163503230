@import "./variables";

.fw-400 {
    font-weight: 400 !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
.body-height {
    height: 92vh;
    @include mediaQuery(md) {
        height: 88vh;
    }
    @include mediaQuery(xxl) {
        height: 92vh;
    }
}
.h-100vh {
    height: 100vh !important;
}

.crud-icon {
    width: 20px;
    height: 20px;
    > svg {
        display: flex;
    }
}

.small-icon {
    width: 24px;
    height: auto;
    > svg {
        display: flex;
    }
}

.medium-icon {
    width: 32px;
    height: auto;
    > svg {
        display: flex;
    }
}

.large-icon {
    width: 48px;
    height: auto;
    > svg {
        display: flex;
    }
}

.table-logo {
    width: auto;
    height: 36px;
    > svg {
        display: flex;
    }
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.error-icon {
    width: 30px;
    height: auto;
}

.text-sentence {
    &::first-letter {
        text-transform: uppercase;
    }
}

.location-icon {
    width: 25px;
    height: auto;
    & > svg {
        display: flex;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.back-arrow {
    width: 20px;
    height: 20px;

    & > svg {
        display: flex;
    }
}

.z-999 {
    z-index: 999 !important;
}

.mt-1px {
    margin-top: 1px;
}

.h-50px {
    height: 50px;
}

.h-53px {
    height: 53px;
}

.campaign-logo {
    width: 100px;
    height: auto;
}

.campaign-image {
    width: 150px;
    height: auto;
}

.large-logo {
    width: 250px;
    height: auto;
}

.phone-number-container {
    width: 150px !important;
}

.fs-xs {
    font-size: 12px !important;
}

.text-lightgray {
    color: lightgray !important;
}

.bg-lightgray {
    background-color: lightgray !important;
}

.bg-darkgray {
    background-color: $dark-gray;
}

.thumbnail-add-container {
    width: 150px !important;
    height: 150px !important;
    font-size: 36px !important;
    font-weight: 600 !important;
    color: $primary !important;
    border: 1px dashed $primary !important;

    &:hover {
        border: 1px solid $primary !important;
    }
}

.thumbnail-view-container {
    width: 150px !important;
    height: 150px !important;

    &:hover {
        .thumbnail-image {
            opacity: 60% !important;
        }

        .thumbnail-remove {
            display: block !important;
            background-color: $primary !important;
            color: white !important;
        }
    }
}
