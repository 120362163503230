@import "../../template/variables";

.lang-switch {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    & > div {
        background-color: white;
        padding: 3px;
    }
    & > div > button {
        outline: none;
        font-weight: 500;
        font-size: 18px;
        border: none;
        color: gray;
    }
    & > div:first-child {
        border-radius: 4px 0px 0px 4px;
        border: 2px solid white;
        border-right: none;
    }
    & > div:first-child > button {
        border-radius: 1rem 0rem 0rem 1rem;
    }
    & > div:last-child {
        border-radius: 0px 4px 4px 0px;
        border: 2px solid white;
        border-left: none;
        button {
            border-radius: 0px 8px 8px 0px;
        }
    }
    & > div:last-child > button {
        border-radius: 0rem 1rem 1rem 0rem;
    }
}

.active-lang {
    background-color: $primary !important;
    color: white !important;
}
