@import "../../template/variables";

.header {
    height: $header-height;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    padding-right: 1.5rem;
    padding-left: 0;
}

.profile {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    padding: 0;
    border: none;
    overflow: hidden;
}

.notification {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
}

.language {
    margin-right: auto;
}
